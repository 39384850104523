const theme = {
  colors: {
    base: '#000000', // Black
    primary: '#6534d4', // Purple
    secondary: '#fd5750', // Pink
    tertiary: '#f3f3f3', // Light Gray
    highlight: '#5b8bf7', // Light Blue
    accent: '#6534D4', // Purple
  },
  sizes: {
    maxWidth: '1200px',
    maxWidthCentered: '768px',
  },
  responsive: {
    small: '576px', // PHONE
    medium: '768px', // TABLET
    large: '992px', // LAPTOP
    xlarge: '1200px', // DESKTOP
  },
};

export default theme;
