import styled from 'styled-components';

export const Wrapper = styled.div`
  .header__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 0;
  }

  a {
    color: #fefefe;
    transition: color 0.2s ease;
    text-decoration: none;

    &:hover {
      color: #ffffff;
    }

    &.active {
      color: #ffffff !important;
      font-weight: 700;
    }
  }

  span {
    display: none;
    text-transform: uppercase;
  }
`;
