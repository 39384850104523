import React from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'gatsby';
import { Container, Row, Col } from 'react-grid-system';
import { Wrapper, WrapperTop } from './footer.css';
import Title from 'components/title';
import Nav from 'components/footer/nav';

const Footer = () => (
  <Wrapper>
    <Container>
      <Row>
        <Col col={12} sm={12}>
          <WrapperTop>
            <Nav />
            <Title tag="span" size="small">
              Copyright © 2018 Trade in Cool LLC. All Rights Reserved.
            </Title>
            {/* <Link to="/" className="footer__title-link">
              <Title tag="span">{title}</Title>
            </Link> */}
          </WrapperTop>
        </Col>
      </Row>
      {/* <Row>
        <Col col={12} sm={12}>
          <WrapperBtm>
            <Title tag="span" size="small">
              Copyright © 2018 Trade in Cool LLC. All Rights Reserved.
            </Title>
          </WrapperBtm>
        </Col>
      </Row> */}
    </Container>
  </Wrapper>
);

Footer.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Footer;
