import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Wrapper = styled.nav`
  ${MEDIA.TABLET`
    display: none;
  `};

  ul {
    display: flex;
    list-style: none;
    padding: 0;

    li {
      text-transform: uppercase;
      font-size: 1.6rem;

      ${MEDIA.MIN_TABLET`
        position: relative;
        height: 67px;
        line-height: 67px;
      `};

      & + li {
        margin-left: 2rem;
      }
    }

    a.active {
      color: inherit;

      ${MEDIA.MIN_TABLET`
        &:before {
          background-color: ${props => props.theme.colors.primary};
          content: '';
          position: absolute;
          top: 0;
          height: 5px;
          width: 100%;
          left: 0;
        }
      `};
    }
  }
`;
