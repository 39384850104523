import { createGlobalStyle } from 'styled-components';
import theme from 'constants/theme';
// Manually injecting as styled-components was upgraded.
// import { injectLayoutBaseCSS } from 'styled-bootstrap-grid';
// injectLayoutBaseCSS(customCSS);
import MEDIA from 'helpers/mediaTemplates';

export default createGlobalStyle`
  @-ms-viewport {
    width: device-width;
  }

  html {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -ms-overflow-style: scrollbar;
  }

  *,
  *::before,
  *::after {
    -webkit-box-sizing: inherit;
            box-sizing: inherit;
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  html {
    font-size: 62.5%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  body {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    line-height: 1;
    font-size: 1.6rem;
    color: #000;
    background-color: #fff;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    //text-rendering: optimizeLegibility;
    text-rendering: optimizeSpeed;
    -webkit-font-feature-settings: "pnum";
    font-feature-settings: "pnum";
    font-variant-numeric: proportional-nums;
  }

  ::selection {
    background: #fff200;
  }

  #___gatsby {
    line-height: 1.6;
  }

  ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  a {
    color: ${theme.colors.primary};
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  pre {
    display: block;
    padding: 2rem;
    margin-top: 4rem;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    border-radius: 5px;
    color: ${theme.colors.base};
    border: 1px solid #ddd;
    font-family: "SFMono-Regular",Consolas,"Liberation Mono",Menlo,Courier,monospace;
  }

  video {
    max-width: 100%;
  }

  p {
    margin-bottom: 2rem;
  }

  .color-primary {
    color: ${theme.colors.base};
    position: relative;
  }

  // .color-primary:before {
  //   background: #F56681;
  //   bottom: 8px;
  //   content: "";
  //   left: -2px;
  //   position: absolute;
  //   right: -2px;
  //   top: 40px;
  //   transform: none;
  //   z-index: -1;
  // }

  .text-center {
    text-align: center;
  }

  .text-center pre {
    text-align: left;
  }

  .hidden {
    display: none;
  }

  h1 {
    // font-family: "Aleo",serif;
    font-family: "Zilla Slab", serif;
    font-size: 3.6rem;
  }

  h1 em:after {
    content: '';
    top: 90%;
    left: 0;
    position: absolute;
    z-index: -1;
    display: block;
    height: 8px;
    width: 100%;
    background-color: #F56681 0%;
    background-image: -webkit-linear-gradient(15deg, #F56681 0%, #F56681 30%, #F4D3A5 100%);
    background-image: -moz-linear-gradient(15deg, #F56681 0%, #F56681 30%, #F4D3A5 100%);
    background-image: -ms-linear-gradient(15deg, #F56681 0%, #F56681 30%, #F4D3A5 100%);
    background-image: -o-linear-gradient(15deg, #F56681 0%, #F56681 30%, #F4D3A5 100%);
    background-image: linear-gradient(15deg, #F56681 0%, #F56681 30%, #F4D3A5 100%);
  }

  .img-responsive {
    max-width: 100%;
    height: auto;
  }

  ${MEDIA.MIN_TABLET`
    .gallery__work {
      .gallery__items {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 4rem;
      }
    }
  `};

  ${MEDIA.MIN_LAPTOP`
    .gallery__work {
      .gallery__items {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 4rem;
      }
    }
  `};

  ${MEDIA.MIN_TABLET`
    .gallery__work {
      .gatsby-image-wrapper {
        max-height: 228px;
        min-height: 228px;
      }
    }
  `};

  :root {
    --reach-dialog: 1;
  }

  [data-reach-dialog-overlay] {
    // background: hsla(0, 0%, 0%, 0.33);
    background: hsla(0, 0%, 0%, 0.8);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    z-index: 100;
  }

  [data-reach-dialog-content] {
    width: 50vw;
    margin: 20vh auto;
    //margin: 10vh auto;
    background: transparent;
    padding: 0;
    outline: none;
  }

  label span {
    font-size: 1rem;
  }

  textarea {
    overflow: auto;
    resize: vertical;
  }

  textarea.form-control {
    height: inherit;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  .form-control {
    background-clip: padding-box;
    background-color: #ffffff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    color: #495057;
    display: block;
    font-size: 1.7rem;
    height: calc(3.25rem + 2px);
    line-height: 1.5;
    padding: .375rem .75rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: 100%;
    font-family: monospace;
  }

  .form-control:focus,
  .form-control:hover {
    outline: 3px solid #6534d4!important;
  }

  .justify-content-end {
    justify-content: flex-end!important;
  }

  [type=reset], [type=submit], button, html [type=button] {
    -webkit-appearance: button;
  }

  // Welcome sections.
  @media only screen and (min-width: 992px) {
    .cube-bg {
      background: #ffffff;
      height: 70vh;
      text-align: center;
      overflow: hidden;
      position: relative;
      margin-left: -15px;
      margin-right: -15px;
      margin-top: -4rem;
      padding-top: 4rem;

      // h1 {
      //   font-size: 6.25rem;
      //   font-weight: 500;
      //   letter-spacing: -0.3125rem;
      //   margin-top: 35vh;
      //   margin-bottom: 20px;
      //   color: #1f2429;
      //   line-height: 1;
      // }

      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 50vw;
        height: 80vh;
        transform: rotate(-25deg);
        z-index: 0;
      }

      &:before {
        left: -15vw;
        top: -45%;
        background: #fff;
        background: -moz-linear-gradient(left, #e2e7ef 0%, #ffffff 100%);
        background: -webkit-linear-gradient(left, #e2e7ef 0%, #ffffff 100%);
        background: linear-gradient(to right, #e2e7ef 0%, #ffffff 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2e7ef', endColorstr='#ffffff', GradientType=1); // Responsive
      }

      &:after {
        right: -15vw;
        top: -45%;
        background: #ffffff;
        background: -moz-linear-gradient(left, #ffffff 0%, #e2e7ef 100%);
        background: -webkit-linear-gradient(left, #ffffff 0%, #e2e7ef 100%);
        background: linear-gradient(to right, #ffffff 0%, #e2e7ef 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e2e7ef', GradientType=1); // Responsive
      }
    }
  }
`;
