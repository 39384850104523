import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Wrapper = styled.footer``;

export const WrapperTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem 0;

  a {
    color: #757575;
    transition: color 0.2s ease;
    text-decoration: none;

    &:hover {
      color: inherit;
    }
  }
  ${MEDIA.TABLET`
    text-align: center;
    padding: 4rem 0 6rem 0;
  `};
`;

export const WrapperBtm = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 4rem 0;

  ${MEDIA.TABLET`
    text-align: center;
    padding: 0 0 6rem 0;
  `};

  ${MEDIA.TABLET`
    .footer__title-link {
      display: none;
    }
  `};
`;
