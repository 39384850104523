import React from 'react';
import { Link } from 'gatsby';
import { Wrapper } from './nav.css';

const Nav = () => (
  <Wrapper>
    <ul>
      <li>
        <Link to="/services" activeClassName="active">
          Services
        </Link>
      </li>
      <li>
        <Link to="/work" activeClassName="active">
          Work
        </Link>
      </li>
      <li>
        <Link to="/agency" activeClassName="active">
          Agency
        </Link>
      </li>
      <li>
        <Link to="/contact" activeClassName="active">
          Contact
        </Link>
      </li>
    </ul>
  </Wrapper>
);

export default Nav;
